<template>
  <div class="border mb-3 py-3" v-if="settingForStoreDiscount">
    <p class="tw-border-start fw-bolder mb-3">{{ title }}</p>
    <template v-if="!storeDiscounts.loading">
      <div class="tw-drop-down-menu" v-if="storeDiscounts.data.length > 0" >
        <div :id="selectMenuId" class="tw-drop-down-menu-select" @click="show">{{ selectStoreDiscount ? selectStoreDiscount.name : "未選擇結單優惠" }}</div>
        <ul :id="menuId" class="tw-drop-down-menu-options-area" :class="{'d-none': !showMenu}">
          <template v-for="(data, index) in storeDiscounts.data" :key="data.id">
            <li>
              <input class="tw-drop-down-menu-options-radio" type="radio" :name="inputRadioName" :id="`${inputRadioId}_${index}`" :value="data" v-model="selectStoreDiscount">
              <label class="tw-drop-down-menu-options-label" :for="`${inputRadioId}_${index}`">
                <!-- 折扣名稱 -->
                <p class="fw-bolder mb-2">{{ data.name }}</p>
                <!-- 折扣內容 -->
                <div class="mb-2">
                  <!-- 滿額折抵 -->
                  <template v-if="data.storeDiscountConditions[0].discountType === 'FULL_AMOUNT_REBATE'">
                    <p>
                      滿{{ data.storeDiscountConditions[0].threshold }}元，總金額折抵{{ data.storeDiscountConditions[0].discount }}元
                      <span v-if="data.storeDiscountConditions[0].progressive">，採累進折抵</span>
                    </p>
                    <p v-if="data.storeDiscountConditions[0].progressive">
                      (滿{{ data.storeDiscountConditions[0].threshold * 2 }}元折抵{{ data.storeDiscountConditions[0].discount * 2 }}元，以此類推)
                    </p>
                  </template>
                  <!-- 滿額打折 -->
                  <template v-else-if="data.storeDiscountConditions[0].discountType === 'FULL_AMOUNT_DISCOUNT'">
                    <p>
                      滿{{ data.storeDiscountConditions[0].threshold }}元，總金額打{{ data.storeDiscountConditions[0].discount }}折
                    </p>
                  </template>
                  <!-- 滿額免運 -->
                  <div v-else-if="data.storeDiscountConditions[0].discountType === 'FULL_AMOUNT_REBATE_SHIPPING_COST'">
                    <p>
                      滿{{ data.storeDiscountConditions[0].threshold }}元，免運費
                    </p>
                  </div>
                  <!-- 滿件免運 -->
                  <div v-else-if="data.storeDiscountConditions[0].discountType === 'FULL_QUANTITY_REBATE_SHIPPING_COST'">
                    <p>
                      滿{{ data.storeDiscountConditions[0].threshold }}件，免運費
                    </p>
                  </div>
                </div>
                <!-- 折扣限制 -->
                <div class="badge bg-danger">
                  <p v-if="!data.storeVip && data.customerGroups.length === 0">
                    會員皆可使用
                  </p>
                  <template v-else>
                    <!-- vip 限制 -->
                    <div title="VIP限制" v-if="data.storeVip">
                      <img class="icon me-2" src="@/assets/icon/crown1.png" />
                      <span> {{ data.storeVip.name }} 以上</span>
                    </div>
                    <!-- 顧客群組限制 -->
                    <div title="顧客群組限制" v-if="data.customerGroups.length > 0">
                      <img class="icon me-2" src="@/assets/icon/users.png" />
                      <span
                        v-for="(customerGroup, key) in data.customerGroups"
                        :key="customerGroup.id"
                      >
                        {{ customerGroup.name }}
                        <span v-if="key !== data.customerGroups.length - 1">
                          、
                        </span>
                      </span>
                    </div>
                  </template>
                </div>
              </label>
            </li>
          </template>
          <!-- 取消選取 -->
          <li @click="$emit('fromPromotionsData', null);">
            <input class="tw-drop-down-menu-options-radio" type="radio" :name="inputRadioName" :id="`${inputRadioId}_00`" :value="null" v-model="selectStoreDiscount">
            <label class="tw-drop-down-menu-options-label" :for="`${inputRadioId}_00`">
              不使用結單優惠
            </label>
          </li>
        </ul>
      </div>
      <p class="text-danger fw-bolder" v-else>您尚未有任何結單優惠!</p>
    </template>
    <AreaLoading v-else></AreaLoading>
  </div>
</template>

<script>
// service
import { checkStoreDiscountIsEnable } from "../../methods/service/storeDiscountService"

// vuex
import { mapState } from "vuex";

export default {
  props: {
    /** 下拉選單區塊 ID
     * 若該元件在畫面上只會出現一個，就不必傳值進來
     */
    menuId: {
      type: String,
      default: "TW_Menu",
    },
    /** 下拉選單選擇後的區塊 ID
     * 若該元件在畫面上只會出現一個，就不必傳值進來
     */
    selectMenuId: {
      type: String,
      default: "TW_SelectMenuId"
    },
    /** input radio ID
     * 若該元件在畫面上只會出現一個，就不必傳值進來
     */
    inputRadioId: {
      type: [String],
      default: "TW_Input_Id"
    },
    /** input radio name
     * 若該元件在畫面上只會出現一個，就不必傳值進來
     */
    inputRadioName: {
      type: [String],
      default: "TW_Input_Name"
    },

    /** Title */
    title: {
      type: String,
      default: "結單優惠"
    },
    /** 顧客 ID */
    participantId: {
      type: [String, Number],
      default: 0,
      required: true,
    },
  },
  emits: ['fromPromotionsData'],
  data() {
    return {
      serverToken: '',
      storeId: 0,
      participantInfo: {},
      storeDiscounts: {
        loading: false,
        data: []
      },
      selectStoreDiscount: null,
      showMenu: false,
    }
  },
  mounted() {
    this.registerListenClickEvent();
  },
  computed: {
    ...mapState("SystemSetting", {
      settingForStoreDiscount: state => state.settingForStoreDiscount,
    }),
  },
  watch: {
    // 父層傳進來的 participantId
    participantId: {
      handler(val) {
        // 從父層傳遞過來的 participantId 因為 immediate: true 的關係
        // 所以觸發的比這裡的 created 還早
        this.initialization()
        this.getParticipantInfo()
      },
      immediate: true
    },
    // 選擇到的結單優惠
    selectStoreDiscount(val) {
      const result = val ? this.handleStoreDiscount(val) : null;
      console.log("選擇到的結單優惠: ", result)
      this.$emit('fromPromotionsData', result);
    },
  },
  methods: {
    // 開啟 / 關閉下拉選單
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    // 註冊監聽點擊事件
    registerListenClickEvent() {
      const vm = this;
      document.addEventListener("click", function(event) {
        // console.dir(event)

        // 點擊特定區域 -> toggle menu，點擊其他區域 -> 一律關閉下拉選單
        const tartgetNodeId = event.target.id;
        if ((vm.menuId === tartgetNodeId && vm.showMenu) || vm.selectMenuId === tartgetNodeId) vm.toggleMenu();
        else vm.showMenu = false;
      });
    },

    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得顧客資訊
    getParticipantInfo() {
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 13,
        ids: [this.participantId],
        methods: '{}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.participantInfo = participantInfo
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreDiscounts{getStoreVip,getStoreDiscountConditions,getCustomerGroups}}'
        }
      ]
      this.storeDiscounts = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.getStoreDiscounts(storeInfo.storeDiscounts)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理結單優惠
    getStoreDiscounts(storeDiscounts) {
      const participantVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      const participantCustomerGroupId = this.participantInfo.customerGroupId ? this.participantInfo.customerGroupId : 0
      storeDiscounts.forEach(storeDiscount => {
        // 檢查賣場促銷是否啟用
        const item = checkStoreDiscountIsEnable(storeDiscount);

        // 判斷賣場促銷的 "顧客群組" 跟 "VIP"
        const discountVipId = item.storeVipId ? item.storeVipId : 0
        if (item.enable && item.siteObjectType === 'BILLHEADER' && participantVipId >= discountVipId) {
          if (item.customerGroups.length === 0) {
            this.storeDiscounts.data.push(item)
          } else {
            const inCustomerGroupInterval = item.customerGroups.some(item => {
              return item.id === participantCustomerGroupId
            })
            if (inCustomerGroupInterval) this.storeDiscounts.data.push(item)
          } 
        }
      });
      this.storeDiscounts.loading = false
    },
    // 整理結單優惠內容
    handleStoreDiscount(storeDiscount) {
      return {
        siteObjectType: storeDiscount.siteObjectType, // BillHeader || MoneyReport
        discountType: storeDiscount.storeDiscountConditions[0].discountType, // 優惠類型 (列舉名稱)
        discountName: storeDiscount.name, // 優惠名稱
        threshold: storeDiscount.storeDiscountConditions[0].threshold,  // 門檻
        discount: storeDiscount.storeDiscountConditions[0].discount, // 折扣值
        progressive: storeDiscount.storeDiscountConditions[0].progressive, // 是否累進計算
      }
    },
  },
}
</script>